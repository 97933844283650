import { euMember } from 'is-european'
import _ from 'lodash'
import {
  AdjustCartParams,
  CartPaymentParams,
  SetUserDataParams,
  SignInParams,
  SubscriberInterface,
  UpdatePrivacyConsentParams,
  ViewItemParams,
} from '../../hooks/events/events.types'
import CartInterface, { CartItemInterface } from '../../interfaces/CartInterface'
import CategoryInterface from '../../interfaces/CategoryInterface'
import ChannelInterface from '../../interfaces/ChannelInterface'
import PrivacyConsentInterface, { PrivacyConsentsFieldEnum } from '../../interfaces/PrivacyConsentInterface'
import ProductInterface, { PriceInterface } from '../../interfaces/ProductInterface'
import { sha256 } from '../../utils/crypto'
import { getPrivacyConsents } from '../../utils/privacyConsent'

type Ga4ItemCategories = {
  item_category?: string
  item_category2?: string
  item_category3?: string
  item_category4?: string
  item_category5?: string
}

type Ga4Item = Ga4ItemCategories & {
  item_id: string
  item_name: string
  item_variant?: string
  price: number
  quantity: number
  google_business_vertical: 'retail'
}

const fromBreadcrumbs = (breadcrumbs?: CategoryInterface[]): Ga4ItemCategories => {
  const result: Ga4ItemCategories = {}
  if (!breadcrumbs || breadcrumbs.length <= 1) return result
  const items = breadcrumbs.map(item => item?.translations['en-AU']?.name) // force en-au translations for analytics
  // skip index 0 as it is category root
  if (items[1]) result.item_category = items[1]
  if (items[2]) result.item_category2 = items[2]
  if (items[3]) result.item_category3 = items[3]
  if (items[4]) result.item_category4 = items[4]
  if (items[5]) result.item_category5 = items[5]
  return result
}

const fromCartItem = (item: CartItemInterface): Ga4Item => {
  return {
    item_id: String(item.productId),
    item_name: item.name,
    item_variant: item.skuCode,
    price: parseFloat(item.priceGross) || 0,
    quantity: item.quantity,
    google_business_vertical: 'retail',
    ...fromBreadcrumbs(item?.product?.parent?.breadcrumbs),
  }
}

const fromCartItems = (cart: CartInterface) => {
  return cart.items.map(fromCartItem)
}

const fromCart = (cart: CartInterface) => {
  return {
    currency: cart.currency,
    value: cart.totalGross,
    items: fromCartItems(cart),
  }
}

const fromProduct = (product: ProductInterface, variantId: number, currency: string, quantity: number = 1): Ga4Item | undefined => {
  const variant: ProductInterface | undefined = _.find(product.variants, (_variant: ProductInterface): boolean => _variant.id === variantId)
  if (!variant) return undefined

  const price: PriceInterface | undefined = _.find(variant.pricing, (_price: PriceInterface): boolean => _price.currency === currency)

  return {
    item_id: String(variant.id),
    item_name: product.translations['en-AU'].name || '',
    item_variant: variant.skuCode,
    price: parseFloat(price?.grossPrice || '0') || 0,
    quantity,
    google_business_vertical: 'retail',
    ...fromBreadcrumbs(product.breadcrumbs),
  }
}

/**
 * This is actually GTM tracker now, but GA4 message oriented
 */
class GA4Tracker implements SubscriberInterface {
  constructor(
    private trackingId: string,
    private sendTo: string[],
    private countryCode: string,
  ) {}

  init = (): void => {
    const consents = getPrivacyConsents()
    this.updatePrivacyConsents({
      type: 'default',
      consents: {
        [PrivacyConsentsFieldEnum.REQUIRED]: true,
        [PrivacyConsentsFieldEnum.MARKETING]: euMember(this.countryCode) ? consents?.[PrivacyConsentsFieldEnum.MARKETING] ?? false : true,
        [PrivacyConsentsFieldEnum.FUNCTIONAL]: euMember(this.countryCode) ? consents?.[PrivacyConsentsFieldEnum.FUNCTIONAL] ?? false : true,
      },
      wait_for_update: 500,
    })
  }

  switchChannel = (channel?: ChannelInterface): void => {
    gtag('event', 'switch_shop', {
      targetShop: channel?.theme!,
    })
  }

  showChannel = (channel?: ChannelInterface): void => {
    if (!channel) return
    const { id, theme, domain, path, type } = channel
    gtag('set', {
      wfChannelId: id,
      wfChannelTheme: theme,
      wfChannelDomain: domain,
      wfChannelPath: path,
      wfChannelUrl: 'https://' + domain + path,
      wfChannelType: type,
    })
  }

  signIn = (data: SignInParams): void => {
    gtag('event', 'login', {
      login_path: data.pathname,
      method: data.method,
    })
  }

  signUp = (data: SignInParams): void => {
    gtag('event', 'sign_up', {
      login_path: data.pathname,
      method: data.method,
    })
  }

  clickCheckout = (cart: CartInterface): void => {
    gtag('event', 'begin_checkout', {
      ...fromCart(cart),
    })
  }

  viewCart = (cart: CartInterface): void => {
    gtag('event', 'view_cart', {
      ...fromCart(cart),
    })
  }

  adjustCart = (data: AdjustCartParams): void => {
    if (data.adjQty === 0) return
    const eventName = data.adjQty > 0 ? 'add_to_cart' : 'remove_from_cart'
    let value = 0
    let items: Ga4Item[] = []

    // TODO: consider report parent product only
    if (data.item) {
      value = parseFloat(data.item.costGross) || 0
      items = [fromCartItem(data.item)]
    } else if (data.product && data.variantId) {
      const ga4Item = fromProduct(data.product, data.variantId, data.currency, data.adjQty)
      if (!ga4Item) return
      items = [ga4Item]
      value = ga4Item.price * ga4Item.quantity
    } else {
      return
    }

    gtag('event', eventName, {
      currency: data.currency,
      value,
      items,
    })
  }

  viewItem = (data: ViewItemParams): void => {
    const ga4Item = fromProduct(data.product, data.product.variants[0].id, data.currency)
    if (!ga4Item) return
    gtag('event', 'view_item', {
      currency: data.currency,
      value: ga4Item.price * ga4Item.quantity,
      items: [ga4Item],
    })
  }

  viewCartAuth = (cart: CartInterface): void => {
    gtag('event', 'view_cart_auth', {
      ...fromCart(cart),
    })
  }

  viewCartShipping = (cart: CartInterface): void => {
    gtag('event', 'view_cart_shipping', {
      ...fromCart(cart),
    })
  }

  viewCartPayment = (cart: CartInterface): void => {
    gtag('event', 'view_cart_payment', {
      ...fromCart(cart),
    })
  }

  addShippingInfo = (cart: CartInterface): void => {
    gtag('event', 'add_shipping_info', {
      ...fromCart(cart),
      shipping_tier: cart.shippingMethod?.name || '',
    })
  }

  clickPayment = (cart: CartInterface): void => {
    gtag('event', 'click_payment', {
      ...fromCart(cart),
    })
  }

  addPaymentInfo = (data: CartPaymentParams): void => {
    gtag('event', 'add_payment_info', {
      ...fromCart(data.cart),
      payment_type: data.method || '',
    })
  }

  purchase = (order: CartInterface): void => {
    gtag('event', 'purchase', {
      ...fromCart(order),
      transaction_id: order.id,
      shipping: order.shippingCostGross,
      tax: order.totalTax,
      coupon: _.isEmpty(order.discounts) ? undefined : order.discounts.map(discount => discount.code).join(';'),
      affiliation: 'Brandshop',
    })
  }

  setUserData = (data: SetUserDataParams): void => {
    gtag('set', 'user_data', {
      sha256_email_address: sha256(data.user.email),
      ...(data.address.phoneNumber != null ? { sha256_phone_number: sha256(data.address.phoneNumber) } : {}),
      address: {
        sha256_first_name: sha256(data.user.firstName),
        sha256_last_name: sha256(data.user.lastName),
        street: data.address.street! + (data.address.street2 != null ? `, ${data.address.street2}` : ''),
        city: data.address.city,
        postal_code: data.address.postCode,
        country: data.address.country,
        region: data.address.state,
      },
    })
  }

  updatePrivacyConsents = (data: UpdatePrivacyConsentParams): void => {
    gtag('consent', data.type, {
      ad_storage: data.consents[PrivacyConsentsFieldEnum.MARKETING] ? 'granted' : 'denied',
      ad_user_data: data.consents[PrivacyConsentsFieldEnum.MARKETING] ? 'granted' : 'denied',
      ad_personalization: data.consents[PrivacyConsentsFieldEnum.MARKETING] ? 'granted' : 'denied',
      analytics_storage: data.consents[PrivacyConsentsFieldEnum.FUNCTIONAL] ? 'granted' : 'denied',
      wait_for_update: data.wait_for_update,
    })
    gtag('event', 'wfConsentUpdate', {})
  }

  updateMetaPixelConsents = (data: PrivacyConsentInterface): void => {
    //not really used in Tag Manager, but keep it for consistency
    // gtag('set', 'pixel_functional_consent', { pixelFunctionalConsent: data[PrivacyConsentsFieldEnum.FUNCTIONAL] })
    gtag('set', {
      pixelFunctionalConsent: data[PrivacyConsentsFieldEnum.FUNCTIONAL],
    })
  }
}

export default (countryCode: string, trackingId?: string): SubscriberInterface => {
  if (!trackingId) return {} as SubscriberInterface // return phantom object if no tracking id
  return new GA4Tracker(trackingId, ['GA4'], countryCode)
}
